var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.activeUserLoading)?_c('ul',{staticClass:"menu-nav"},[_c('li',{staticClass:"menu-item menu-item-active",attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":"#"}},[_c('b-icon',{staticClass:"menu-icon",attrs:{"icon":"gear","animation":"spin"}}),_c('span',{staticClass:"menu-text"},[_vm._v("Loading....")])],1)])]):(!_vm.activeUserLoading && !_vm.isKYCVerified)?_c('ul',{staticClass:"menu-nav"},[_c('router-link',{attrs:{"to":"/kyc-kyb"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-user"}),_c('span',{staticClass:"menu-text"},[_vm._v("KYC - KYB")])])])]}}])})],1):(!_vm.activeUserLoading && _vm.isKYCVerified)?_c('ul',{staticClass:"menu-nav"},[_c('router-link',{attrs:{"to":"/dashboard"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-architecture-and-city"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("ROUTES.HOME")))])])])]}}])}),(_vm.isMerchant || _vm.isBranch)?_c('router-link',{attrs:{"to":"/wallet"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fa fa-wallet"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("ROUTES.WALLET")))])])])]}}],null,false,578024820)}):_vm._e(),(_vm.userRole.canSellQrCode)?_c('li',{staticClass:"menu-item menu-item-submenu",class:{
        'menu-item-open': _vm.hasActiveChildren('/sales-management')
      },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"menu-icon flaticon-price-tag"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("ROUTES.SALES_MANAGEMENT")))]),_c('i',{staticClass:"menu-arrow"})]),_c('div',{staticClass:"menu-submenu pl-5"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[_c('li',{staticClass:"menu-item menu-item-parent",attrs:{"aria-haspopup":"true"}},[_c('span',{staticClass:"menu-link"},[_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("ROUTES.SALES_MANAGEMENT")))])])]),_c('router-link',{attrs:{"to":{ name: 'sales-list' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("ROUTES.ALL_SALES")))])])])]}}],null,false,4078220435)}),_c('router-link',{attrs:{"to":{ name: 'link-sales' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("ROUTES.QR_SALE")))])])])]}}],null,false,421163650)})],1)])]):_vm._e(),_c('li',{staticClass:"menu-item menu-item-submenu d-none",class:{
        'menu-item-open': _vm.hasActiveChildren('/wallet-management')
      },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"menu-icon flaticon-layers"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("ROUTES.WALLET_MANAGEMENT")))]),_c('i',{staticClass:"menu-arrow"})]),_c('div',{staticClass:"menu-submenu pl-5"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[_c('li',{staticClass:"menu-item menu-item-parent",attrs:{"aria-haspopup":"true"}},[_c('span',{staticClass:"menu-link"},[_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("ROUTES.WALLET_MANAGEMENT")))])])]),_c('router-link',{attrs:{"to":"/wallet-management/list"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("ROUTES.WALLETS")))])])])]}}])}),_c('router-link',{attrs:{"to":"/wallet-management/withdrawal"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("ROUTES.WITHDRAWALS")))])])])]}}])})],1)])]),(_vm.isDistributor || _vm.isMerchant)?_c('router-link',{attrs:{"to":{ name: 'merchant-branch-report-list' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-layers"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("ROUTES.MERCHANT_BRANCH_REPORT")))])])])]}}],null,false,4012808274)}):_vm._e(),(_vm.isDistributor)?_c('router-link',{attrs:{"to":{ name: 'refunded-sales-list' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-layers"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("ROUTES.REFUNDED_SALES")))])])])]}}],null,false,695707034)}):_vm._e(),_c('li',{staticClass:"menu-item menu-item-submenu d-none",class:{
        'menu-item-open': _vm.hasActiveChildren('/personel-management')
      },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"menu-icon flaticon2-group"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("ROUTES.PERSONNEL_MANAGEMENT")))]),_c('i',{staticClass:"menu-arrow"})]),_c('div',{staticClass:"menu-submenu pl-5"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[_c('li',{staticClass:"menu-item menu-item-parent",attrs:{"aria-haspopup":"true"}},[_c('span',{staticClass:"menu-link"},[_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("ROUTES.PERSONNEL_MANAGEMENT")))])])]),_c('router-link',{attrs:{"to":{ name: 'personel-management' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("ROUTES.PERSONNEL")))])])])]}}])}),_c('router-link',{attrs:{"to":{ name: 'personel-add' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("ROUTES.NEW_PERSONNEL")))])])])]}}])})],1)])]),(_vm.userRole.canSubDeal)?_c('li',{staticClass:"menu-item menu-item-submenu",class:{ 'menu-item-open': _vm.hasActiveChildren('/corporate') },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"menu-icon flaticon2-user"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("ROUTES.CORPORATE")))]),_c('i',{staticClass:"menu-arrow"})]),_c('div',{staticClass:"menu-submenu pl-5"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[_c('li',{staticClass:"menu-item menu-item-parent",attrs:{"aria-haspopup":"true"}},[_c('span',{staticClass:"menu-link"},[_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("ROUTES.CORPORATE")))])])]),_c('router-link',{attrs:{"to":{ name: 'company-companies' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(" "+_vm._s(_vm.isMerchant ? _vm.$t("ROUTES.BRANCHES") : _vm.$t("ROUTES.MERCHANTS"))+" ")])])])]}}],null,false,2307898750)})],1)])]):_vm._e(),(_vm.isDistributor || _vm.isMerchant)?_c('li',{staticClass:"menu-item menu-item-submenu",class:{
        'menu-item-open': _vm.hasActiveChildren('/pos')
      },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"menu-icon fa fa-credit-card"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("PAGES.SALES_MANAGEMENT.POS.POS")))]),_c('i',{staticClass:"menu-arrow"})]),_c('div',{staticClass:"menu-submenu pl-5"},[_c('ul',{staticClass:"menu-subnav"},[_c('router-link',{attrs:{"to":{ name: 'pos-list' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("ROUTES.POS_LIST")))])])])]}}],null,false,398573711)})],1)])]):_vm._e(),_c('li',{staticClass:"menu-item menu-item-submenu",class:{ 'menu-item-open': _vm.hasActiveChildren('/settings') },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"menu-icon flaticon2-settings"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("ROUTES.SETTINGS")))]),_c('i',{staticClass:"menu-arrow"})]),_c('div',{staticClass:"menu-submenu pl-5"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[_c('li',{staticClass:"menu-item menu-item-parent",attrs:{"aria-haspopup":"true"}},[_c('span',{staticClass:"menu-link"},[_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("ROUTES.SETTINGS")))])])]),_c('router-link',{attrs:{"to":{ name: 'settings-general' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("ROUTES.COMPANY")))])])])]}}])}),_c('router-link',{attrs:{"to":{ name: 'settings-users' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("ROUTES.USERS")))])])])]}}])}),_c('router-link',{attrs:{"to":{
              name: 'settings-users-user-profile',
              params: { profile: true, deleteable: false }
            }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("ROUTES.PROFILE")))])])])]}}])}),_c('router-link',{attrs:{"to":{ name: 'change-password' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("ROUTES.CHANGE_PASSWORD")))])])])]}}])})],1)])]),_c('li',{staticClass:"menu-item",attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",on:{"click":function($event){$event.preventDefault();return _vm.logout.apply(null, arguments)}}},[_vm._m(0),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("ROUTES.LOGOUT")))])])]),_vm._m(1)],1):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('i',{staticClass:"menu-icon fa fa-sign-out-alt"},[_c('span')])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"menu-section d-none"},[_c('h4',{staticClass:"menu-text"},[_vm._v("Default")]),_c('i',{staticClass:"menu-icon flaticon-more-v2"})])
}]

export { render, staticRenderFns }