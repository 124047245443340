<template>
  <div class="topbar-item">
    <b-dropdown
      size="lg"
      variant="link"
      toggle-class="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2 btn-dropdown btn-lg text-decoration-none"
      no-caret
      menu-class="w-auto"
      right
      no-flip
      id="dropdown-quick-user"
    >
      <template v-slot:button-content>
        <span
          class="text-muted font-weight-bold font-size-base d-md-inline mr-1"
        >
          {{ $t("MENU.GREET") }},
        </span>
        <span
          class="text-dark-50 font-weight-bolder font-size-base d-md-inline mr-3"
        >
          {{ getFullName }}
        </span>
        <span
          class="text-dark-50 font-weight-bolder font-size-base d-md-inline"
        >
          <i :class="dropdownIconClass"></i>
        </span>
      </template>
      <b-dropdown-text tag="div" class="py-4">
        <b-dropdown-item :to="{ name: 'change-password' }">
          <span class="symbol symbol-20 mr-1">
            <i class="menu-icon flaticon2-lock text-dark">
              <span></span>
            </i>
          </span>
          <span>{{ $t("ROUTES.CHANGE_PASSWORD") }}</span>
        </b-dropdown-item>
        <b-dropdown-item @click="onLogout">
          <span class="symbol symbol-20 mr-1">
            <i class="menu-icon fa fa-sign-out-alt text-danger">
              <span></span>
            </i>
          </span>
          <span class="text-danger">{{ $t("ROUTES.LOGOUT") }}</span>
        </b-dropdown-item>
      </b-dropdown-text>
    </b-dropdown>
  </div>
</template>

<style lang="scss" scoped>
#kt_quick_user {
  overflow: hidden;
}
</style>

<script>
import { mapGetters } from "vuex";
import { LOGOUT } from "@/store/modules/auth";

export default {
  name: "KTQuickUser",
  data() {
    return {
      dropdownIconClass: "fa fa-chevron-down"
    };
  },
  mounted() {
    this.$root.$on("bv::dropdown::show", bvEvent => {
      if (bvEvent.componentId === "dropdown-quick-user")
        this.dropdownIconClass = "fa fa-chevron-up";
    });
    this.$root.$on("bv::dropdown::hide", bvEvent => {
      if (bvEvent.componentId === "dropdown-quick-user")
        this.dropdownIconClass = "fa fa-chevron-down";
    });
  },
  methods: {
    onLogout() {
      this.$store.dispatch(LOGOUT).then(() => {
        window.location.href = "/login";
        // this.$router.push({ name: "login" });
      });
    }
  },
  computed: {
    ...mapGetters(["activeUser"]),

    getFullName() {
      return this.activeUser.firstName + " " + this.activeUser.lastName;
    }
  }
};
</script>
