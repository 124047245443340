<template>
  <div>
    <ul class="menu-nav" v-if="activeUserLoading">
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-active"
      >
        <a href="#" class="menu-link">
          <b-icon icon="gear" animation="spin" class="menu-icon"></b-icon>
          <span class="menu-text">Loading....</span>
        </a>
      </li>
    </ul>
    <ul class="menu-nav" v-else-if="!activeUserLoading && !isKYCVerified">
      <router-link
        to="/kyc-kyb"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active'
          ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <i class="menu-icon flaticon2-user"></i>
            <span class="menu-text">KYC - KYB</span>
          </a>
        </li>
      </router-link>
    </ul>
    <ul class="menu-nav" v-else-if="!activeUserLoading && isKYCVerified">
      <router-link
        to="/dashboard"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active'
          ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <i class="menu-icon flaticon2-architecture-and-city"></i>
            <span class="menu-text">{{ $t("ROUTES.HOME") }}</span>
          </a>
        </li>
      </router-link>
      <router-link
        to="/wallet"
        v-slot="{ href, navigate, isActive, isExactActive }"
        v-if="isMerchant || isBranch"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active'
          ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <i class="menu-icon fa fa-wallet"></i>
            <span class="menu-text">{{ $t("ROUTES.WALLET") }}</span>
          </a>
        </li>
      </router-link>
      <li
        v-if="userRole.canSellQrCode"
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        v-bind:class="{
          'menu-item-open': hasActiveChildren('/sales-management')
        }"
      >
        <a href="#" class="menu-link menu-toggle">
          <i class="menu-icon flaticon-price-tag"></i>
          <span class="menu-text">{{ $t("ROUTES.SALES_MANAGEMENT") }}</span>
          <i class="menu-arrow"></i>
        </a>
        <div class="menu-submenu pl-5">
          <span class="menu-arrow"></span>
          <ul class="menu-subnav">
            <li aria-haspopup="true" class="menu-item menu-item-parent">
              <span class="menu-link">
                <span class="menu-text">{{
                  $t("ROUTES.SALES_MANAGEMENT")
                }}</span>
              </span>
            </li>
            <router-link
              :to="{ name: 'sales-list' }"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-bullet menu-bullet-dot">
                    <span></span>
                  </i>
                  <span class="menu-text">{{ $t("ROUTES.ALL_SALES") }}</span>
                </a>
              </li>
            </router-link>
            <router-link
              :to="{ name: 'link-sales' }"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-bullet menu-bullet-dot">
                    <span></span>
                  </i>
                  <span class="menu-text">{{ $t("ROUTES.QR_SALE") }}</span>
                </a>
              </li>
            </router-link>
          </ul>
        </div>
      </li>

      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu d-none"
        v-bind:class="{
          'menu-item-open': hasActiveChildren('/wallet-management')
        }"
      >
        <a href="#" class="menu-link menu-toggle">
          <i class="menu-icon flaticon-layers"></i>
          <span class="menu-text">{{ $t("ROUTES.WALLET_MANAGEMENT") }}</span>
          <i class="menu-arrow"></i>
        </a>
        <div class="menu-submenu pl-5">
          <span class="menu-arrow"></span>
          <ul class="menu-subnav">
            <li aria-haspopup="true" class="menu-item menu-item-parent">
              <span class="menu-link">
                <span class="menu-text">{{
                  $t("ROUTES.WALLET_MANAGEMENT")
                }}</span>
              </span>
            </li>

            <router-link
              to="/wallet-management/list"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-bullet menu-bullet-dot">
                    <span></span>
                  </i>
                  <span class="menu-text">{{ $t("ROUTES.WALLETS") }}</span>
                </a>
              </li>
            </router-link>
            <router-link
              to="/wallet-management/withdrawal"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-bullet menu-bullet-dot">
                    <span></span>
                  </i>
                  <span class="menu-text">{{ $t("ROUTES.WITHDRAWALS") }}</span>
                </a>
              </li>
            </router-link>
          </ul>
        </div>
      </li>

      <router-link
        v-if="isDistributor || isMerchant"
        :to="{ name: 'merchant-branch-report-list' }"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active'
          ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <i class="menu-icon flaticon2-layers"></i>
            <span class="menu-text">{{
              $t("ROUTES.MERCHANT_BRANCH_REPORT")
            }}</span>
          </a>
        </li>
      </router-link>

      <router-link
        v-if="isDistributor"
        :to="{ name: 'refunded-sales-list' }"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active'
          ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <i class="menu-icon flaticon2-layers"></i>
            <span class="menu-text">{{ $t("ROUTES.REFUNDED_SALES") }}</span>
          </a>
        </li>
      </router-link>

      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu d-none"
        v-bind:class="{
          'menu-item-open': hasActiveChildren('/personel-management')
        }"
      >
        <a href="#" class="menu-link menu-toggle">
          <i class="menu-icon flaticon2-group"></i>
          <span class="menu-text">{{ $t("ROUTES.PERSONNEL_MANAGEMENT") }}</span>
          <i class="menu-arrow"></i>
        </a>
        <div class="menu-submenu pl-5">
          <span class="menu-arrow"></span>
          <ul class="menu-subnav">
            <li aria-haspopup="true" class="menu-item menu-item-parent">
              <span class="menu-link">
                <span class="menu-text">{{
                  $t("ROUTES.PERSONNEL_MANAGEMENT")
                }}</span>
              </span>
            </li>

            <router-link
              :to="{ name: 'personel-management' }"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-bullet menu-bullet-dot">
                    <span></span>
                  </i>
                  <span class="menu-text">{{ $t("ROUTES.PERSONNEL") }}</span>
                </a>
              </li>
            </router-link>
            <router-link
              :to="{ name: 'personel-add' }"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-bullet menu-bullet-dot">
                    <span></span>
                  </i>
                  <span class="menu-text">{{
                    $t("ROUTES.NEW_PERSONNEL")
                  }}</span>
                </a>
              </li>
            </router-link>
          </ul>
        </div>
      </li>

      <li
        v-if="userRole.canSubDeal"
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        v-bind:class="{ 'menu-item-open': hasActiveChildren('/corporate') }"
      >
        <a href="#" class="menu-link menu-toggle">
          <i class="menu-icon flaticon2-user"></i>
          <span class="menu-text">{{ $t("ROUTES.CORPORATE") }}</span>
          <i class="menu-arrow"></i>
        </a>
        <div class="menu-submenu pl-5">
          <span class="menu-arrow"></span>
          <ul class="menu-subnav">
            <li aria-haspopup="true" class="menu-item menu-item-parent">
              <span class="menu-link">
                <span class="menu-text">{{ $t("ROUTES.CORPORATE") }}</span>
              </span>
            </li>
            <router-link
              :to="{ name: 'company-companies' }"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-bullet menu-bullet-dot">
                    <span></span>
                  </i>
                  <span class="menu-text">
                    {{
                      isMerchant
                        ? $t("ROUTES.BRANCHES")
                        : $t("ROUTES.MERCHANTS")
                    }}
                  </span>
                </a>
              </li>
            </router-link>

            <!-- <router-link
              :to="{ name: 'sales-representative-list' }"
              v-slot="{ href, navigate, isActive, isExactActive }"
              v-if="isDistributor"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-bullet menu-bullet-dot">
                    <span></span>
                  </i>
                  <span class="menu-text">{{
                    $t("ROUTES.SALES_REPRESENTATIVES")
                  }}</span>
                </a>
              </li>
            </router-link> -->
          </ul>
        </div>
      </li>

      <li
        v-if="isDistributor || isMerchant"
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        v-bind:class="{
          'menu-item-open': hasActiveChildren('/pos')
        }"
      >
        <a href="#" class="menu-link menu-toggle">
          <i class="menu-icon fa fa-credit-card"></i>
          <span class="menu-text">{{
            $t("PAGES.SALES_MANAGEMENT.POS.POS")
          }}</span>
          <i class="menu-arrow"></i>
        </a>
        <div class="menu-submenu pl-5">
          <ul class="menu-subnav">
            <router-link
              :to="{ name: 'pos-list' }"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-bullet menu-bullet-dot">
                    <!-- <span></span> -->
                  </i>
                  <span class="menu-text">{{ $t("ROUTES.POS_LIST") }}</span>
                </a>
              </li>
            </router-link>
          </ul>
        </div>
      </li>

      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        v-bind:class="{ 'menu-item-open': hasActiveChildren('/settings') }"
      >
        <a href="#" class="menu-link menu-toggle">
          <i class="menu-icon flaticon2-settings"></i>
          <span class="menu-text">{{ $t("ROUTES.SETTINGS") }}</span>
          <i class="menu-arrow"></i>
        </a>
        <div class="menu-submenu pl-5">
          <span class="menu-arrow"></span>
          <ul class="menu-subnav">
            <li aria-haspopup="true" class="menu-item menu-item-parent">
              <span class="menu-link">
                <span class="menu-text">{{ $t("ROUTES.SETTINGS") }}</span>
              </span>
            </li>

            <router-link
              :to="{ name: 'settings-general' }"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-bullet menu-bullet-dot">
                    <span></span>
                  </i>
                  <span class="menu-text">{{ $t("ROUTES.COMPANY") }}</span>
                </a>
              </li>
            </router-link>
            <router-link
              :to="{ name: 'settings-users' }"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-bullet menu-bullet-dot">
                    <span></span>
                  </i>
                  <span class="menu-text">{{ $t("ROUTES.USERS") }}</span>
                </a>
              </li>
            </router-link>
            <router-link
              :to="{
                name: 'settings-users-user-profile',
                params: { profile: true, deleteable: false }
              }"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-bullet menu-bullet-dot">
                    <span></span>
                  </i>
                  <span class="menu-text">{{ $t("ROUTES.PROFILE") }}</span>
                </a>
              </li>
            </router-link>
            <router-link
              :to="{ name: 'change-password' }"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-bullet menu-bullet-dot">
                    <span></span>
                  </i>
                  <span class="menu-text">{{
                    $t("ROUTES.CHANGE_PASSWORD")
                  }}</span>
                </a>
              </li>
            </router-link>
          </ul>
        </div>
      </li>
      <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item">
        <a class="menu-link" @click.prevent="logout">
          <i class="menu-icon fa fa-sign-out-alt">
            <span></span>
          </i>
          <span class="menu-text">{{ $t("ROUTES.LOGOUT") }}</span>
        </a>
      </li>

      <li class="menu-section d-none">
        <h4 class="menu-text">Default</h4>
        <i class="menu-icon flaticon-more-v2"></i>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { LOGOUT } from "../../../store/modules/auth";

export default {
  name: "KTMenu",
  computed: {
    ...mapGetters([
      "activeUser",
      "userRole",
      "isMerchant",
      "isDistributor",
      "isBranch",
      "isKYCVerified"
    ]),
    activeUserLoading() {
      if (!this.activeUser || Object.keys(this.activeUser).length === 0) {
        return true;
      }
      return false;
    }
  },
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },
    logout() {
      this.$store.dispatch(LOGOUT).then(() => {
        window.location.href = "/login";
        // this.$router.push({ name: "login" })
      });
    }
  }
};
</script>
