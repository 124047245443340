<template>
  <div class="overlay-loader" v-if="overlayVisible">
    <b-spinner variant="primary" label="Text Centered"></b-spinner>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["overlayVisible"])
  },
  watch: {
    overlayVisible(newVal) {
      if (newVal) {
        this.disableScroll();
      } else {
        this.enableScroll();
      }
    }
  },
  methods: {
    disableScroll() {
      document.body.style.overflow = "hidden";
    },
    enableScroll() {
      document.body.style.overflow = "";
    }
  },
  mounted() {
    if (this.overlayVisible) {
      this.disableScroll();
    }
  },
  beforeDestroy() {
    this.enableScroll();
  }
};
</script>

<style scoped>
.overlay-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999999;
}
</style>
