<template>
  <!-- begin:: Footer -->
  <div class="footer bg-white py-4 d-flex flex-lg-column" id="kt_footer">
    <div
      class="d-flex align-items-center justify-content-between"
      v-bind:class="{ 'container-fluid': widthFluid, container: !widthFluid }"
    >
      <div class="text-dark">
        <span class="text-muted font-weight-bold mr-2">
          {{ year }} &nbsp;&copy;&nbsp;
        </span>
        <a
          :href="mpayLink"
          target="_blank"
          class="text-dark-75 text-hover-primary"
        >
          MiraclePay
        </a>
      </div>
      <div class="nav nav-dark d-none">
        <a href="#" target="_blank" class="nav-link pr-3 pl-0"> About </a>
        <a href="#" target="_blank" class="nav-link px-3"> Team </a>
        <a href="#" target="_blank" class="nav-link pl-3 pr-0"> Contact </a>
      </div>
    </div>
  </div>
  <!-- end:: Footer -->
</template>

<script>
import { mapGetters } from "vuex";
import i18nService from "@/services/i18n.js";

export default {
  name: "KTFooter",
  computed: {
    ...mapGetters(["layoutConfig"]),
    year() {
      return new Date().getFullYear();
    },

    /**
     * Check if footer container is fluid
     */
    widthFluid() {
      return this.layoutConfig("footer.width") === "fluid";
    },
    activeLanguage() {
      return i18nService.getActiveLanguage();
    },
    mpayLink() {
      return `https://miraclepay.com/${this.activeLanguage || "tr"}`;
    }
  }
};
</script>
